import React from 'react'
import PropTypes from 'prop-types'
import StyledBikeSelectorCard from './style'
import { Text } from '../../../Text'
import { Icon } from '../../../Icon'
import { Content } from '../../../Content'

const BikeSelectorCard = props => {
  const { image, text, checked, clickHandler } = props
  const Image = image ? image : null
  return (
    <StyledBikeSelectorCard checked={checked} onClick={clickHandler}>
      <Icon size={26} icon={'done'} />
      <Image />
      <Content>
        <Text>{text}</Text>
      </Content>
    </StyledBikeSelectorCard>
  )
}

BikeSelectorCard.propTypes = {
  checked: PropTypes.bool,
  image: PropTypes.func,
  text: PropTypes.string,
  clickHandler: PropTypes.func,
}

export { BikeSelectorCard }
