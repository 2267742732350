import React, { useState, useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SlickSlider from 'react-slick'

import StyledBikeSelectorQuestions from './style'
import {
  AppContext,
  setBikeSelectorQuestions,
  toggleBikeSelectorAnswer,
} from '../../../config/context'
import { TEXT_SIZES, SPACING, COLORS } from '../../../helpers/constants'
import { PageContent } from '../../PageContent'
import { Container } from '../../Container'
import { Content } from '../../Content'
import { Heading } from '../../Heading'
import { Text } from '../../Text'
import { useTranslation } from '../../../hooks/useTranslation'
import { Grid } from '../../Grid'
import {
  BikeWithGuy,
  BikeFlatTiers,
  BikeCompact,
  BikeBox,
  TachometerHigh,
  TachometerLow,
  PathCurved,
  PathFlat,
  UserFriends,
  User,
} from '../../Svg'
import { BikeSelectorCard } from './BikeSelectorCard'
import { Button } from '../../Button'
import { Divider } from '../../Divider'
import { getUrl } from '../../../helpers/url'

const getIcon = icon => {
  const icons = {
    comfortable: BikeWithGuy,
    sporty: BikeFlatTiers,
    compact: BikeCompact,
    box: BikeBox,
    tachometer_high: TachometerHigh,
    tachometer_low: TachometerLow,
    path_flat: PathFlat,
    path_curved: PathCurved,
    user: User,
    user_friends: UserFriends,
  }
  return icons[icon]
}

const BikeSelectorQuestions = props => {
  const { data } = props
  const {
    bikeSelectorQuestions: questions,
    bikeSelectorResultPageLink: linkToResult,
  } = data

  const [state, dispatch] = useContext(AppContext)
  const { bikeSelectorQuestions } = state

  const getTranslation = useTranslation()

  useEffect(() => {
    dispatch(setBikeSelectorQuestions(questions))
  }, [])

  const sliderRef = useRef(null)
  const [atSlideIndex, setAtSlideIndex] = useState(0)

  if (!bikeSelectorQuestions) return null

  const getArrow = direction => {
    return (
      <Arrow
        direction={direction}
        atSlideIndex={atSlideIndex}
        totalSlides={bikeSelectorQuestions.length}
        linkToResult={linkToResult}
      />
    )
  }

  const speed = 500

  const settings = {
    nextArrow: getArrow('next'),
    prevArrow: getArrow('prev'),
    infinite: false,
    speed: speed,
    slidesToShow: 1,
    slidesToScroll: 1,
    // adaptiveHeight: true,
    fade: true,
    beforeChange: (current, next) =>
      setTimeout(() => setAtSlideIndex(next), speed / 2),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          adaptiveHeight: true,
        },
      },
    ],
  }

  return (
    <StyledBikeSelectorQuestions>
      <Container>
        <PageContent>
          <StyledBikeSelectorQuestions.Indicator>
            <Divider size={SPACING.XX_SMALL} />
            <span>
              {getTranslation('ebikeSelectorText')} {atSlideIndex + 1} /{' '}
              {bikeSelectorQuestions.length}
            </span>
          </StyledBikeSelectorQuestions.Indicator>

          <SlickSlider {...settings} ref={sliderRef}>
            {bikeSelectorQuestions.map((question, questionIndex) => {
              return (
                <React.Fragment key={questionIndex}>
                  <StyledBikeSelectorQuestions.TextWrapper>
                    <Content>
                      <Heading size={1} type={'h2'}>
                        {question.questionTitle}
                      </Heading>
                      {question.questionDescription && (
                        <Text>{question.questionDescription}</Text>
                      )}
                    </Content>
                  </StyledBikeSelectorQuestions.TextWrapper>
                  <Grid col={4}>
                    {question.answerAlternatives.map(
                      (answerAlternative, alternativeIndex) => {
                        return (
                          <BikeSelectorCard
                            key={alternativeIndex}
                            text={answerAlternative.answerTitle}
                            image={getIcon(answerAlternative.answerIcon)}
                            clickHandler={() => {
                              dispatch(
                                toggleBikeSelectorAnswer(
                                  questionIndex,
                                  alternativeIndex
                                )
                              )
                            }}
                            checked={answerAlternative.checked}
                          />
                        )
                      }
                    )}
                  </Grid>
                </React.Fragment>
              )
            })}
          </SlickSlider>
        </PageContent>
      </Container>
      <StyledBikeSelectorQuestions.Footer />
    </StyledBikeSelectorQuestions>
  )
}

const Arrow = props => {
  const { onClick, direction, atSlideIndex, totalSlides, linkToResult } = props
  const atLastSlide = () => atSlideIndex >= totalSlides - 1
  const getTranslation = useTranslation()

  return direction === 'prev' && atSlideIndex === 0 ? null : (
    <StyledBikeSelectorQuestions.ArrowWrapper direction={direction}>
      <Button
        icon={direction === 'next' ? 'arrow_forward' : 'arrow_back'}
        iconBefore={direction === 'prev'}
        handleClick={atLastSlide() && direction === 'next' ? null : onClick}
        href={
          direction === 'next' && atLastSlide()
            ? getUrl(linkToResult.url)
            : null
        }
        textColor={COLORS.SECONDARY_LIGHT_BLUE}
      >
        {direction === 'next' &&
          !atLastSlide() &&
          getTranslation('forwardsText')}
        {direction === 'next' && atLastSlide() && linkToResult.title}
        {direction === 'prev' && getTranslation('backwardsText')}
      </Button>
    </StyledBikeSelectorQuestions.ArrowWrapper>
  )
}

export { BikeSelectorQuestions }
