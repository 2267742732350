import styled from 'styled-components'
import { colors, spacing, mq } from '../../../../styles/utilities/variables'
import StyledIcon from '../../../Icon/style'
import StyledText from '../../../Text/style'

const StyledBikeSelectorCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${colors.white};
  border-radius: 19px;
  padding: 10rem ${spacing.small} ${spacing.small};
  min-height: 360px;
  height: 100%;
  cursor: pointer;

  @media ${mq.mobile} {
    padding: ${spacing.base};
    min-height: 100%;
  }

  ${StyledIcon} {
    position: absolute;
    top: ${spacing.base};
    right: ${spacing.base};
    cursor: pointer;

    i {
      background-color: ${props =>
        props.checked ? colors.primaryDarkBlue : 'transparent'};
      border-radius: 100%;
      border: 1px solid ${colors.primaryDarkBlue};
      cursor: pointer;
      color: ${props => (props.checked ? colors.white : 'transparent')};
      padding: 1px;
      position: absolute;
      top: 1rem;
      right: 1rem;

      @media ${mq.mobile} {
        background-color: ${props =>
          props.checked ? colors.white : 'transparent'};
        color: ${props =>
          props.checked ? colors.primaryDarkBlue : 'transparent'};
      }
    }
  }

  @media ${mq.mobile} {
    align-items: flex-start;
    background-color: ${props =>
      props.checked ? colors.secondaryYellow : colors.white};
  }

  & ${StyledText} {
    text-align: center;
  }

  & svg {
    min-height: 10rem;
    margin-bottom: ${spacing.xLarge};

    @media ${mq.mobile} {
      display: none;
    }
  }

  @media ${mq.mobile} {
    ${StyledText} {
      text-align: start;
      margin: ${spacing.base} ${spacing.xxLarge} ${spacing.xxLarge}
        ${spacing.base};
    }

    & svg {
      display: none;
    }
  }
`

export default StyledBikeSelectorCard
