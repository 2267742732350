import styled from 'styled-components'
import { rgba } from 'polished'
import { spacing, colors, mq } from '../../../styles/utilities/variables'
import StyledHeading from '../../../components/Heading/style'
import StyledDivider from '../../Divider/style'

const StyledBikeSelectorQuestions = styled.div`
  background: ${rgba(colors.secondaryYellow, 0.1)};

  .slick-initialized .slick-slide.slick-active {
    z-index: 1;
  }
`

StyledBikeSelectorQuestions.Indicator = styled.div`
  position: absolute;
  top: 0;
  left: ${spacing.large};
  padding-top: ${spacing.xxxLarge};
  text-transform: uppercase;

  @media ${mq.tablet} {
    padding-top: ${spacing.xLarge};
    left: ${spacing.medium};
  }

  @media ${mq.mobile} {
    padding: 0;
    left: 0;
    width: 100%;
    padding: ${spacing.small} 0 ${spacing.small} ${spacing.medium};
    background: ${colors.secondaryYellow};
    color: ${colors.primaryDarkBlue};

    & ${StyledDivider} {
      display: none;
    }
  }
`

StyledBikeSelectorQuestions.ArrowWrapper = styled.div`
  position: absolute;
  bottom: -15rem;
  left: ${props => (props.direction === 'prev' ? '10px' : 'auto')};
  right: ${props => (props.direction === 'next' ? '10px' : 'auto')};

  @media ${mq.tablet} {
    bottom: -13.2rem;
  }

  @media ${mq.mobile} {
    bottom: -11rem;
  }
`

StyledBikeSelectorQuestions.TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: calc(${spacing.xxxLarge} * 2);
  padding-bottom: ${spacing.xxLarge};

  @media ${mq.mobile} {
    padding-left: 0;
    padding-top: ${spacing.xxxLarge};
    padding-bottom: ${spacing.base};

    & ${StyledHeading.h2} {
      font-size: 30px;
      font-weight: bold;
    }
  }
`

StyledBikeSelectorQuestions.Footer = styled.div`
  width: 100%;
  background: ${colors.secondaryYellow};
  height: 13rem;
`

export default StyledBikeSelectorQuestions
