import React from 'react'
import { graphql } from 'gatsby'
import { parseSeo } from '../../helpers/seo'
import Layout from '../../views/Layout'
import SEO from '../../views/Seo'
import { BikeSelectorQuestions } from '../../components/BikeSelector/Questions'

export default ({ data, pageContext }) => {
  const page = data.wordpress.page
  const { seo, acfPageTemplateBikeSelectorQuestions } = page

  return (
    <Layout>
      <SEO {...parseSeo(seo)} />
      <BikeSelectorQuestions data={acfPageTemplateBikeSelectorQuestions} />
    </Layout>
  )
}

export const query = graphql`
  query($id: ID!) {
    wordpress {
      page(id: $id) {
        ...Single_Page
        acfPageTemplateBikeSelectorQuestions {
          bikeSelectorResultPageLink {
            title
            url
          }
          bikeSelectorQuestions {
            questionTitle
            questionDescription
            answerAlternatives {
              answerTitle
              answerIcon
              answerResultTitle
              answerResultText
              answerResultLinks {
                link {
                  title
                  url
                }
              }
              answerProducts
            }
          }
        }
      }
    }
  }
`
